<template>
  <div>
    <div class="card-toolbar mb-5">
      <router-link v-if="$can('laundry_services.create')" class="btn btn-primary font-weight-bolder" to="/settings/laundry-services/create">
        <v-icon>mdi-plus</v-icon>
        {{ $t('laundry_services.add_laundry_service') }}
      </router-link>

      <button class="btn btn-outline-primary font-weight-bolder ml-5" @click="openAdvancedSearch">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div v-if="showAdvancedSearch" class="card card-custom mb-5">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_name">{{ $t('laundry_services.name') }}</label>
              <input id="f_name" v-model="filters.name" class="form-control" type="text">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select id="f_status" v-model="filters.is_active" class="custom-select" name="" type="text">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :key="index" :value="row.id">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i>
                {{ $t('search') }}
              </b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i>
                {{ $t('reset_search') }}
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <div>
          <div class="d-flex justify-content-end mb-5">
            <custom-export-data v-if="$can('laundry_services.export_data')" :data-list="dataList" :fields="json_fields"
                                :file-name="fileName"></custom-export-data>
          </div>
        </div>

        <v-server-table ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">

          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('laundry_services.change_status')" :key="props.row.id"
                             v-model="props.row.is_active" :name="'check-button'+props.row.id"
                             size="lg" switch
                             @change="changeStatus(props.row.id, props.row.is_active)"></b-form-checkbox>
            <b-form-checkbox v-else :key="'is_active'+ props.row.id" v-model="props.row.is_active"
                             :name="'check-button'+props.row.id" size="lg"
                             switch></b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-btn v-if="$can('laundry_services.update')" icon color="pink" :to="`/settings/laundry-services/edit/${props.row.id}`">
              <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')">mdi-pencil</v-icon>
            </v-btn>

            <v-icon v-if="$can('laundry_services.delete')" v-b-tooltip.hover :title="$t('delete')"
                    class="mr-2 text-danger"
                    small @click="deleteItem(props.row)">mdi-delete
            </v-icon>
          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-laundry_services",
  components: {},
  data() {
    return {
      mainRoute: 'settings/mobile_app_settings',
      subMainRoute: 'settings/laundry_service',
      mainRouteDependency: 'base/dependency',
      showAdvancedSearch: false,
      filters: {
        name: null,
        is_active: null,
      },
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['name', 'service_order', 'status', 'actions'],

      idEditing: null,
      isEditing: false,

      disabledButton: false,
      validation: [],
      dataList: [],
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('laundry_services.name')] = 'name';
      fields[this.$t('laundry_services.order')] = 'service_order';
      fields[this.$t('laundry_services.notes')] = 'notes';
      fields[this.$t('status')] = 'status';
      return fields;
    },
    fileName: function () {
      return this.$t('MENU.laundry_services');
    },
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('laundry_services.name'),
          service_order: that.$t('laundry_services.order'),
          notes: that.$t('laundry_services.notes'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },

  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.mobile_app_settings")}]);
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.is_active = null;

      this.$refs.table.refresh();
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((errors) => {
        this.$errorAlert(errors);
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? 1 : 0),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
  },
};
</script>
